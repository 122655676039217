import React, { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getItem, setItem } from '../../utility/localStorage';

const UserLogin = ({ handleChange, setRegistration, admin }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [firstname, setFirstname] = useState("");
  const [laststname, setLaststname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [conpassword, setConPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [registration, setRegistrationState] = useState(false); 
  const toast = useToast();
  const token = getItem("token"); 

  const handleRegister = async () => {
    if (password === conpassword) {
      const name = `${firstname} ${laststname}`;

      try {
        setIsLoading(true);
        const response = await fetch("https://martadmin.logiclync.com/api/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            password,
            password_confirmation: conpassword,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          toast({
            title: "Registration successful",
            description: `Welcome, ${data.user.name}!`,
            status: "success",
            duration: 6000,
            isClosable: true,
          });

          setItem("userData", data.user);
          setRegistrationState(false); // Changed this line
        } else {
          toast({
            title: "Registration Failed",
            description: data.message || "Something went wrong!",
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "An error occurred",
          description: error.message,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      toast({
        title: "Passwords do not match",
        description: "Please make sure both passwords match.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await fetch("https://martadmin.logiclync.com/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: "Login successful",
          description: `Welcome back, ${data.user.name}!`,
          status: "success",
          duration: 6000,
          isClosable: true,
        });

        setItem("userData", data.user);
        setItem("token", data.token); // Save token to local storage
        onClose(); // Close the modal on successful login
      } else {
        toast({
          title: "Login Failed",
          description: data.message || "Something went wrong!",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "An error occurred",
        description: error.message,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const response = await fetch("https://martadmin.logiclync.com/api/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Pass the token in the header
        },
      });

      if (response.ok) {
        toast({
          title: "Logout successful",
          description: "You have been logged out successfully.",
          status: "success",
          duration: 6000,
          isClosable: true,
        });

        // Clear user data and token from local storage
        setItem("userData", null);
        setItem("token", null);
        window.location.reload(); // Optionally reload the page to reset the state
      } else {
        const data = await response.json();
        toast({
          title: "Logout Failed",
          description: data.message || "Something went wrong!",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "An error occurred",
        description: error.message,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {token === null ? (
        <Button
          onClick={onOpen}
          fontSize={{ base: "12px", md: "14px" }}
          fontFamily="arial"
          background="-webkit-gradient(linear,left top,left bottom,from(#058b80),to(#02625a))"
          colorScheme="#fff"
          size="md"
          p="5px 40px"
        >
          Login/Signup
        </Button>
      ) : (
        <Button
          onClick={handleLogout}
          fontSize={{ base: "12px", md: "14px" }}
          fontFamily="arial"
          background="-webkit-gradient(linear,left top,left bottom,from(#058b80),to(#02625a))"
          colorScheme="#fff"
          size="md"
          p="5px 40px"
        >
          Logout
        </Button>
      )}

      {!admin && (
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody textAlign={"center"} pb={6}>
              {!registration ? (
                <Flex mt={20} flexDirection={"column"} gap={2}>
                  <Heading color={"#333"}>Login</Heading>
                  <Input
                    placeholder="Email..."
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    placeholder="Password..."
                    type={"password"}
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    onClick={handleLogin}
                    fontFamily="arial"
                    background="-webkit-gradient(linear,left top,left bottom,from(#058b80),to(#02625a))"
                    colorScheme="#fff"
                    mr={3}
                  >
                    {isLoading ? <Spinner /> : "Login"}
                  </Button>
                  <Button
                    onClick={() => setRegistrationState(true)} // Changed this line
                    fontFamily="arial"
                    background="-webkit-gradient(linear,left top,left bottom,from(#058b80),to(#02625a))"
                    colorScheme="#fff"
                    mr={3}
                  >
                    Create an account
                  </Button>
                </Flex>
              ) : (
                <Flex flexDirection={"column"} gap={2}>
                  <Heading color={"#333"}>Registration</Heading>
                  <Input
                    placeholder="First name..."
                    name="firstname"
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                  <Input
                    placeholder="Last name..."
                    name="laststname"
                    onChange={(e) => setLaststname(e.target.value)}
                  />
                  <Input
                    placeholder="Email..."
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    placeholder="Password..."
                    name="password"
                    type={"password"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Input
                    placeholder="Confirm Password..."
                    type={"password"}
                    onChange={(e) => setConPassword(e.target.value)}
                  />
                  <Button
                    onClick={handleRegister}
                    fontFamily="arial"
                    background="-webkit-gradient(linear,left top,left bottom,from(#058b80),to(#02625a))"
                    colorScheme="#fff"
                    mr={3}
                  >
                    {isLoading ? <Spinner /> : "Sign Up"}
                  </Button>
                  <Button
                    onClick={() => setRegistrationState(false)} // Changed this line
                    fontFamily="arial"
                    background="-webkit-gradient(linear,left top,left bottom,from(#058b80),to(#02625a))"
                    colorScheme="#fff"
                    mr={3}
                  >
                    Already have an account?
                  </Button>
                </Flex>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default UserLogin;
